import React, { ReactElement, useRef, useState } from 'react';
import { useMessageGetter } from '@messageformat/react';
import { useQuery } from '@tanstack/react-query';
import classNames from 'classnames';
import { Link, NavLink, useLocation, useMatch } from 'react-router-dom';
import { Divider, PopoverMenu } from '@shape-construction/arch-ui';
import { Avatar } from '@shape-construction/arch-ui/src/Avatar';
import { FolderIcon, PlusCircleIcon } from '@shape-construction/arch-ui/src/Icons/solid';
import { useClickAway } from '@shape-construction/hooks';
import projectIdReplacer from 'app/lib/utils/projectid-replacer';
import { getJoinedProjectsQueryOptions } from 'app/queries/projects/projects';

export interface ProjectsMenuProps {
  children: ReactElement;
}

const ProjectsMenu: React.FC<ProjectsMenuProps> = ({ children }) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const messages = useMessageGetter('projectsMenu');
  const location = useLocation();
  const { data: joinedProjects } = useQuery(getJoinedProjectsQueryOptions());
  const [open, setOpen] = useState(false);
  const toggleOpenPopover = () => setOpen(!open);
  const closePopover = () => setOpen(false);

  useClickAway(menuRef, closePopover, ['click']);

  const isProjectRoute = useMatch({ path: '/projects/:projectId', end: false });
  const isProjectIssueRoute = useMatch('/projects/:projectId/issues/:issueId/*');
  const isProjectTeamDetailsRoute = useMatch('/projects/:projectId/settings/teams/:id');
  const isProjectShiftReportDetailsRoute = useMatch(
    '/projects/:projectId/shift-reports/:shiftReportId'
  );
  const isProjectWeeklyPlanRoute = useMatch('/projects/:projectId/weekly-planner/plans/:planId/*');

  const generateProjectURL = (projectId: string) => {
    const defaultURL = `/projects/${projectId}/issues`;

    if (isProjectIssueRoute) return { to: defaultURL };
    if (isProjectTeamDetailsRoute) return { to: `/projects/${projectId}/settings/teams` };
    if (isProjectShiftReportDetailsRoute) return { to: `/projects/${projectId}/shift-reports` };
    if (isProjectWeeklyPlanRoute) return { to: `/projects/${projectId}/weekly-planner/plans` };
    if (isProjectRoute)
      return {
        to: projectIdReplacer(location.pathname, projectId),
        state: location.state,
      };

    return { to: defaultURL };
  };
  return (
    <PopoverMenu
      placement="bottom-start"
      open={open}
      onClickButton={toggleOpenPopover}
      isStatic
      className="flex min-w-0"
      content={
        <div ref={menuRef} id="menuRef">
          <div className="max-h-[260px] w-full overflow-y-auto md:max-w-[400px]">
            {joinedProjects?.map((project) => (
              <NavLink
                key={project.id}
                aria-label="project item"
                onClick={closePopover}
                className={({ isActive }) =>
                  classNames(
                    'hover:bg-gray-100 px-4 py-2 flex items-center justify-start text-sm font-normal leading-5',
                    {
                      'bg-gray-100': isActive,
                    }
                  )
                }
                {...generateProjectURL(project.id)}
              >
                <Avatar
                  variant="rounded"
                  imgURL={project.logoUrl || ''}
                  text={project.shortName}
                  size="lg"
                />
                <span className="ml-2 overflow-hidden text-ellipsis whitespace-nowrap">
                  {project.title}
                </span>
              </NavLink>
            ))}
          </div>
          <Divider orientation="horizontal" />

          <nav className="flex flex-col text-sm font-normal leading-5">
            <Link
              to="/my-projects"
              onClick={closePopover}
              className="flex py-3 px-4 hover:bg-gray-100"
            >
              <FolderIcon className="mr-3 h-5 w-5 text-gray-400" /> {messages('allProjects')}
            </Link>
            <Link
              to="/my-projects/new"
              state={{ background: location }}
              onClick={closePopover}
              className="flex py-3  px-4 hover:bg-gray-100"
            >
              <PlusCircleIcon className="mr-3 h-5 w-5 text-gray-400" /> {messages('newProject')}
            </Link>
          </nav>
        </div>
      }
    >
      {children}
    </PopoverMenu>
  );
};

export { ProjectsMenu };
